/* Global Variables */

/* Rem: Consistent Size*/
/* Em: Relative Scaling*/

:root {
  /* Gaps */
  --smallGap: 0.5rem;
  --mediumGap: 1rem;
  --largeGap: 2rem;
  --largerGap: 10rem;

  /* Icon Paddings */
  --smallIconPadding: 0.25rem;
  --roundIconPadding: 1rem;

  /* Color Blending Percentage */

  --highColorPercentage: 80%;
  --lowColorPercentage: 20%;

  /* Colors */

  /* --primaryColor: rgb(39, 33, 33); */
  --primaryColor: rgb(0, 0, 0);

  --lightColor: white;
  --darkColor: black;
  --opaqueColor: rgba(255, 255, 255, 0.2);
  --transparentBackgroundColor: rgba(255, 255, 255, 0.025);
  --successColor: rgb(0 255 153);
  --errorColor: rgb(255, 70, 70);

  --lightPrimaryColor: rgba(255, 255, 255, 0.6);
  --darkPrimaryColor: rgba(0, 0, 0, 0.6);

  --l2SurBackgroundColor: rgb(100, 0, 0);
  --roSurBackgroundColor: #59348a;
  --essenceBackgroundColor: rgb(143, 89, 58);

  --googleColor: rgb(156, 236, 179);
  --facebookColor: rgb(120, 167, 230);

  /* Text Font Sizes */
  --smallText: 1.25rem;
  --mediumText: 1.75rem;
  --largeText: 2.5rem;
  --largerText: 5rem;

  /* Text Thicknesses */
  --lightThickness: 300;
  --normalThickness: 600;
  --boldThickness: 900;

  /* Border Radiuses */
  --smallRadius: 5px;
  --mediumRadius: 10px;
  --largeRadius: 20px;

  /* Icon Font Sizes */
  --smallerIcon: 0.75rem;
  --smallIcon: 1.5rem;
  --mediumIcon: 5rem;
  --largeIcon: 6rem;

  /* Border */
  --primaryBorder: 0.13rem solid var(--lightColor);
  --secondaryBorder: 0.06rem solid var(--lightColor);

  --opaquePrimaryBorder: 0.13rem solid var(--opaqueColor);
  --opaqueSecondaryBorder: 0.06rem solid var(--opaqueColor);

  --validBorder: 0.13rem solid var(--successColor);
  --invalidBorder: 0.13rem solid var(--errorColor);

  /* Grid Size 100 / 8 = 12.5 */
  /* Child Widths */
  --shortWidth: 25%;
  --mediumWidth: 50%;
  --longWidth: 75%;

  /* Animation Times */
  --shortTime: 0.2s;
  --mediumTime: 0.4s;
  --longTime: 1s;

  /* Shadows */
  --primaryShadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  --secondaryShadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --iconShadow: var(--primaryHighlightColor) var(--smallIconPadding)
    var(--smallIconPadding) 0px;
}

/* CSS */

html {
  /* Root Font Sizes */
  font-size: 16px;
}

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  font-family: "Passion One", sans-serif;
  font-weight: 400;
  color: var(--lightPrimaryColor);
}

body {
  background-color: var(--primaryColor);
}

/* Elements Font Sizes */

h1 {
  color: var(--lightColor);
  font-family: "Luckiest Guy", cursive;
  font-size: var(--largeText);
  font-weight: 700;
}

h2 {
  color: var(--lightColor);
  font-family: "Luckiest Guy", cursive;
  font-size: var(--mediumText);
}

h3 {
  color: var(--lightColor);
  font-family: "Luckiest Guy", cursive;
  font-size: 1.25rem;
}

p {
  font-size: var(--smallText);
}

a {
  font-size: var(--smallText);
  text-decoration: none;
}

small {
  color: var(--lightColor);
  font-size: var(--smallText);
}

strong {
  color: var(--lightColor) !important;
}

sub {
  color: var(--lightColor);
  font-size: var(--mediumText);
}

img,
span {
  display: block !important;
}

img {
  z-index: 2;
}

span {
  user-select: none;
  color: var(--lightSaturatedPrimaryColor);
  font-size: var(--smallIcon);
}

hr {
  border: var(--opaqueSecondaryBorder);
}

.material-symbols-rounded {
  margin: 0;
  padding: 0;
  font-size: var(--smallIcon);
  font-variation-settings:
    "FILL" 0,
    "wght" var(--normalThickness),
    "GRAD" 0,
    "opsz" 24;
}

button {
  width: 100%;
  border-radius: var(--mediumRadius);
  border: var(--primaryBorder);
  background-color: transparent;
}

button * {
  color: var(--lightColor);
}

button:hover {
  transition: var(--shortTime);
  background-color: var(--lightColor) !important;
}

button:hover * {
  transition: var(--shortTime);
  color: var(--darkColor) !important;
  filter: invert(0) !important;
}

.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--smallGap);
  padding: var(--smallGap);
}

.googleButton img {
  height: var(--smallIcon);
  width: var(--smallIcon);
  filter: invert(1);
}

.googleButton:hover {
  transition: var(--shortTime);
  background-color: var(--googleColor) !important;
  border-color: var(--googleColor);
}

.googleButton:hover * {
  transition: var(--shortTime);
  color: var(--darkColor) !important;
  filter: invert(0) !important;
}

a:hover {
  transition: var(--shortTime);
  color: var(--lightColor) !important;
}

.link {
  border-radius: var(--mediumRadius);
  border: var(--opaquePrimaryBorder);
  color: var(--opaqueColor);
}

.link:hover {
  border: var(--primaryBorder);
}

.appContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

/* .rootContainer {
  z-index: 5;
} */

.background {
  width: 100%;
  padding: var(--largerGap) 0 var(--largeGap) 0;
}

.container {
  width: var(--longWidth);
  margin: 0 auto;
}

.mediumIcon {
  font-size: var(--mediumIcon);
  padding: var(--largeIconPadding);
  background-color: var(--primaryDarkColor);
  border-radius: var(--mediumRadius);
}

.roundIcon {
  font-size: var(--mediumIcon);
  padding: var(--roundIconPadding);
  background-color: var(--darkTextColor);
  color: var(--lightTextColor);
  border: var(--primaryBorder);
  border-radius: 50%;
}

/* Media Queries */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  html {
    font-size: 10px;
  }

  :root {
    --longWidth: 87.5%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  html {
    font-size: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}
