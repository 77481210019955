#projects {
  position: relative;
  display: flex;
  justify-content: center;
  background-color: var(--primaryColor);
  height: 100%;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--largeGap);
}

.projectsContainer > * {
  z-index: 1;
}

#projects .colorBackground {
  position: absolute;
  width: 100%;
  height: 25%;
  top: -12.5%;
  left: 0;
  background-image: linear-gradient(
    to top,
    var(--primaryColor),
    var(--essenceBackgroundColor)
  );
}

.projectsContainer .mainTitle {
  width: 50%;
}

.projectsContainer .cards {
  width: 100%;
  display: flex;
  gap: var(--mediumGap);
  justify-content: space-between;
}

.projectsContainer .cards img {
  width: 100%;
  border-radius: var(--mediumRadius);
  filter: saturate(1.5);
}

.projectsContainer .radialBackground {
  position: relative;
  width: 100%;
  margin-top: auto;
}

.projectsContainer .radialBackground h2 {
  text-wrap: wrap;
}

.projectsContainer .radialBackground > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--mediumRadius);
  background-image: radial-gradient(circle, transparent, rgb(111, 0, 255) 400%);
  z-index: 3;
}

.projectsContainer .cards > div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--mediumGap);
  padding: var(--largeGap);
  border: var(--opaquePrimaryBorder);
  border-radius: var(--largeRadius);
  background-color: var(--transparentBackgroundColor);
}

.projectsContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--smallGap);
  padding: var(--smallGap);
}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 992px) {
  .projectsContainer .cards {
    flex-direction: column;
  }

  .projectsContainer .mainTitle {
    width: 100%;
  }
}
