.descriptionContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--primaryColor);
}

.descriptionContainer .mainTitle {
  display: flex;
  flex-direction: column;
  width: var(--longWidth);
  margin: 0 auto;
  padding: var(--largeGap) 0;
}

/*  */

.descriptionContainer .infoBackground {
  position: relative;
  background-color: var(--primaryColor);
  padding-top: var(--largeGap);
}

.descriptionContainer .l2SurBackground {
  position: relative;
  padding-top: var(--largerGap);
  background-color: var(--l2SurBackgroundColor);
}

.descriptionContainer .roSurBackground {
  position: relative;
  padding-top: var(--largerGap);
  background-color: var(--roSurBackgroundColor);
}

.descriptionContainer .essenceBackground {
  position: relative;
  padding-top: var(--largerGap);
  background-color: var(--essenceBackgroundColor);
}

#description .l2SurBackground .colorBackground,
#description .roSurBackground .colorBackground,
#description .essenceBackground .colorBackground {
  position: absolute;
  height: 50%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

#description .roSurBackground .colorBackground {
  top: -25%;
  position: absolute;
  background-image: linear-gradient(
    to top,
    var(--roSurBackgroundColor),
    var(--primaryColor)
  );
}

#description .l2SurBackground .colorBackground {
  top: -25%;
  position: absolute;
  background-image: linear-gradient(
    to top,
    var(--l2SurBackgroundColor),
    var(--roSurBackgroundColor)
  );
}

#description .essenceBackground .colorBackground {
  top: -25%;
  position: absolute;
  background-image: linear-gradient(
    to top,
    var(--essenceBackgroundColor),
    var(--l2SurBackgroundColor)
  );
}

/*  */

.descriptionContainer .leftContainer,
.descriptionContainer .rightContainer {
  width: var(--longWidth);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--largeGap);
}

.descriptionContainer img {
  width: 100%;
  z-index: 2;
}

.descriptionContainer .teamImage {
  filter: saturate(1.5);
}

.descriptionContainer .buttons {
  display: flex;
  gap: var(--mediumGap);
}

.descriptionContainer .leftContainer > div,
.descriptionContainer .rightContainer > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--mediumGap);
  z-index: 1;
}

.descriptionContainer .trim {
  max-height: 50vh;
  overflow: hidden;
  border-radius: var(--mediumRadius);
}

/* .descriptionContainer .videoContainer iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border-radius: var(--mediumRadius);
} */

.descriptionContainer video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  border-radius: var(--mediumRadius);
  z-index: 2;
}

.descriptionContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--smallGap);
  padding: var(--smallGap);
}

.descriptionContainer a img {
  height: var(--smallIcon);
  width: var(--smallIcon);
  filter: invert(1);
}

/* Large devices (laptops/desktops, 992px and down) */
@media only screen and (max-width: 992px) {
  .descriptionContainer .leftContainer,
  .descriptionContainer .rightContainer {
    flex-direction: column;
  }
}
