#footer {
  position: relative;
  z-index: 0;

  background-color: var(--lightPrimaryColor);
  background-image: url("../assets/background.png");
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
}

#footer .colorBackground {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* background-color: color-mix(in oklab, var(--primaryColor) 25%, white 0%); */
  background-image: linear-gradient(to bottom, var(--darkColor), transparent);
  backdrop-filter: blur(8px);
  filter: saturate(2);
  z-index: 0;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  gap: var(--largeGap);
}

.footerContainer > * {
  z-index: 1;
}

.footerContainer .contact {
  display: flex;
  flex-direction: row;
  gap: var(--largeGap);
}

.footerContainer .contact > * {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: var(--mediumGap);
}

.footerContainer .contact > * > div {
  display: flex;
  align-items: center;
  gap: var(--smallGap);
}

.footerContainer .bottom {
  display: flex;
  flex-direction: column;
}

.footerContainer .social {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--mediumGap);
  margin-bottom: var(--mediumGap);
}

.footerContainer .social h2 {
  margin-right: auto;
}

.footerContainer .social .icons {
  height: calc(var(--largeGap) * 1);
  width: fit-content;
  display: flex;
  gap: var(--mediumGap);
}

.footerContainer .social .icons a {
  height: 100%;
}

.footerContainer .social .icons img {
  height: 100%;
  filter: invert(100%);
}

.footerContainer .politics {
  display: flex;
  gap: var(--mediumGap);
  margin: 0 auto;
}

.footerContainer .politics > * {
  color: var(--lightSaturatedPrimaryColor);
}

.footerContainer .social .logo {
  height: calc(var(--largeGap) * 2);
  padding: var(--smallIconPadding);
  color: black !important;
}

.footerContainer button img {
  size: 1rem;
}

.footerContainer a {
  width: fit-content;
}

@media only screen and (max-width: 600px) {
  .footerContainer .contact {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .footerContainer .social {
    flex-wrap: nowrap;
  }
}
