#auth {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--largeGap);
}

#auth .colorBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right top,
    rgba(189, 0, 0, 0.5),
    rgba(190, 130, 0, 0.5)
  );
  backdrop-filter: blur(24px);
}

#auth .videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

#auth video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

#auth .authContainer {
  z-index: 1;
  width: 25%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--mediumGap);
  padding: var(--largeGap);
  border: var(--opaquePrimaryBorder);
  border-radius: var(--largeRadius);
  background-color: rgba(0, 0, 0, 0.5);
}

.authContainer .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--mediumGap);
}

.authContainer .logIn {
  display: flex;
  flex-direction: column;
  gap: var(--smallGap);
}

.authContainer .logIn > div {
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--mediumRadius);
  background-color: white;
}

.authContainer .invitation,
.authContainer #userLogged {
  display: flex;
  align-items: center;
  gap: var(--mediumGap);
}

.authContainer .invitation > div,
.authContainer #userLogged > div {
  width: 100%;
  height: 0;
  border: var(--opaqueSecondaryBorder);
}

.authContainer .invitation p,
.authContainer #userLogged p {
  text-wrap: nowrap !important;
}

.authContainer #userLogged,
.authContainer #logOutButton {
  display: none !important;
}

.authContainer .form span {
  padding: var(--mediumGap);
  color: black;
  border-radius: var(--mediumRadius);
}

.authContainer .form button {
  padding: var(--smallGap);
}

.authContainer input {
  height: 100%;
  width: 100%;
  padding: var(--mediumGap);
  border: 2px solid white;
  border-radius: var(--mediumRadius);
  color: black;
  font-size: var(--smallText);
}

.authContainer .errorMessage {
  color: var(--errorColor);
}

.authContainer .validInput {
  background-color: color-mix(in oklab, var(--successColor) 25%, white 0%);
}

.authContainer .invalidInput {
  background-color: transparent;
}

@media only screen and (max-width: 992px) {
  #auth .authContainer {
    width: var(--longWidth);
    position: absolute;
  }
}
